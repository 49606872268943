import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

const HeroBannerCarousel = ({ slides }) => {
  let carouselSlides = slides.map((slide, index) => {
    const slideImage = getImage(slide.image.localFile.hero)
    const active = index === 0 ? "active" : ""
    return (
      <div className={`carousel-item ${active}`} key={index}>
        <GatsbyImage
          className={`d-block w-100`}
          image={slideImage}
          alt={slide.image.alt ? slide.image.alt : ""}
        />
      </div>
    )
  })

  let carouselIndicators = slides.map((slide, index) => {
    const active = index === 0 ? "active" : ""
    return (
      <button
        className={active}
        key={index}
        type="button"
        data-bs-target="#hero-carousel"
        data-bs-slide-to={index}
        aria-label={`Go to slide ${index+1}`}
      ></button>
    )
  })

  return (
    <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel">
      <div className="slide-wrapper">
        <div className="carousel-indicators">{carouselIndicators}</div>

        <div className="carousel-inner">{carouselSlides}</div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#hero-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#hero-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="triangle-bg">
        <StaticImage src="./../../images/triangle.png" alt="triangle-bg" />
      </div>
      <div className={`hero-banner-overlay`}>
        <div className="row justify-content-center">
          <div className={`col-12 col-md-10  m-auto`}>
            <div className="content">
              {slides && slides[0] && slides[0].title ? (
                <h1 className="card-title">
                  Do your personal details impact your job application?
                </h1>
              ) : (
                <></>
              )}

              {slides && slides[0] && slides[0].description ? (
                <p className="card-text">
                  Let’s see what happens when you swap out some of the small
                  details on your curriculum vitae.
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroBannerCarousel
