import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

const HeroBannerSingle = ({ data }) => {
  const slide = data[0]
  const bannerImage = getImage(slide.image.localFile.hero)
  return (
    <>
      <div className="hero-item">
        <GatsbyImage
          className={`card-img`}
          image={bannerImage}
          alt={slide.image.alt ? slide.image.alt : ""}
        />
      </div>
      <div className="triangle-bg">
        <StaticImage src="./../../images/triangle.png" alt="triangle-bg" />
      </div>
      <div className={`hero-banner-overlay`}>
        <div className="row justify-content-center">
          <div className={`col-12 col-md-10  m-auto`}>
            <div className="content">
              <h1 className="card-title">{slide.title}</h1>
              <p className="card-text">{slide.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default HeroBannerSingle
