import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/layout"
import Seo from "../components/global/Seo/seo"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import Cvswapout from "../components/Cvswapout/Cvswapout"

export const homePage = graphql`
  query homePage {
    prismicCvswapoutHome {
      data {
        name
        seo_metadata {
          document {
            ...seoData
          }
        }
        hero_banner {
          document {
            ...heroBannerData
          }
        }
        cv_review {
          document {
            ...cvSwapoutData
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo data={data.prismicCvswapoutHome.data.seo_metadata.document.data} />
      <HeroBanner
        data={data.prismicCvswapoutHome.data.hero_banner.document.data}
      />
      <Cvswapout
        data={data.prismicCvswapoutHome.data.cv_review.document.data}
      />
    </Layout>
  )
}
export default IndexPage
