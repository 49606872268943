import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import "./Tooltip.scss"

const Tooltip = ({tooltipContent}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [toggleTooltip, setToggleTooltip] = useState(false);
  const [tooltipButtonIcon, setTooltipButtonIcon] = useState('i');

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } },
                { name: 'offset', options: { offset: [-38,-2] } }],
    placement: 'top-end',
  });
  const handleClick = (e) => {
    setToggleTooltip(toggleTooltip ? false : true);
    setTooltipButtonIcon((tooltipButtonIcon === 'i') ? 'Χ' : 'i')
    
  }
  const aLable = (tooltipButtonIcon === 'i') ? 'Open help' : 'Close help'

  return (
    <>
      <button className={`tooltip-button`} type="button" ref={setReferenceElement} onClick={handleClick} ariaLabel={aLable}> 
        {tooltipButtonIcon}
      </button>
      {toggleTooltip &&
        <div className={`tooltip-wrap`} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {tooltipContent}
          <div className={`tooltip-arrow`} ref={setArrowElement} style={styles.arrow} />
        </div>
      }
    </>
  );
};

export default Tooltip
