import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import CTALink from "../global/CTALink/CTALink"
import Tooltip from "../global/Tooltip/Tooltip"
import NextImage from "./../../images/next-arrow.svg"
import "./Cvswapout.scss"

export const cvSwapoutFragment = graphql`
  fragment cvSwapoutData on PrismicCvswapoutCvReviewStages {
    data {
      title
      review_stages {
        stage {
          document {
            ... on PrismicCvswapoutReviewStage {
              data {
                type
                label
                description
                background_colour
                help_text
                values {
                  value_mapping {
                    document {
                      ... on PrismicCvswapoutReviewValueMapping {
                        data {
                          source {
                            richText
                            html
                          }
                          summary {
                            richText
                            html
                          }
                          type
                          value
                          score
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      final_summary {
        document {
          ... on PrismicCvswapoutFinalReviewSummary {
            id
            data {
              title
              description {
                html
                richText
              }
              about_cta {
                document {
                  ... on PrismicCvswapoutCta {
                    id
                    data {
                      label
                      href
                      open_in_new_tab
                    }
                  }
                }
              }
              download_cta {
                document {
                  ... on PrismicCvswapoutCta {
                    id
                    data {
                      label
                      href
                      open_in_new_tab
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Cvswapout = ({ data }) => {
  //const [nameStageResult, setNameStageResult] = useState('Nothing selected');
  const [currentActiveStage, setCurrentActiveStage] = useState(0)
  const [selectedNameStage, setSelectedNameStage] = useState("Name")
  const [selectedAgeStage, setSelectedAgeStage] = useState("Age")
  const [selectedEduStage, setSelectedEduStage] = useState("Education")
  const [selectedWorkStage, setSelectedWorkStage] = useState("WorkHistory")
  const [score, setScore] = useState(0)

  const title = data.title
  const stages = data.review_stages
  const summary = data.final_summary.document.data
  let allDisableStages = []
  let disableStages
  const isBrowser = typeof window !== "undefined"

  const getScore = (stage, value) => {
    for(let n = 0; n < stages.length; n++) {
      if(stage === stages[n].stage.document.data.type) {
        for(let m = 0; m < stages[n].stage.document.data.values.length; m++) {
          if(value === stages[n].stage.document.data.values[m].value_mapping.document.data.value) {
            return parseInt(stages[n].stage.document.data.values[m].value_mapping.document.data.score)
          }
        }
        return 0
      }
    } 
    return 0
  }

  useEffect(() => {
    setScore(
      getScore("Name", selectedNameStage) +
      getScore("Age", selectedAgeStage) +
      getScore("Education", selectedEduStage) +
      getScore("WorkHistory", selectedWorkStage)
    )
  }, [getScore, setScore, selectedNameStage, selectedAgeStage, selectedEduStage, selectedWorkStage])

  function handleActivateNextStage(value, nextid, e) {
    e.preventDefault()
    setCurrentActiveStage(value)
    if (isBrowser) {
      var nextStageEl = document.getElementById(nextid).nextSibling

      if (nextStageEl)
        setTimeout(function () {
          nextStageEl.scrollIntoView()
        }, 300)
    }
  }

  function handleChange(event) {
    const { value } = event.target ? event.target : ""
    const { name } = event.target ? event.target : ""

    if (!name || !value) return

    const hideAllResults = elem => elem.classList.add("hide")

    if (isBrowser) {
      var allWrapperEl = document.querySelectorAll(
        ".result-wrap-" + createStageResultId(name)
      )
      var currentWrapperEl = document.getElementById(
        "result-" + createStageResultId(value)
      )
      var currentStageWrapperEl = document.getElementById(
        "cv-criteria-result-" + createStageResultId(name)
      )
      //var nextStageEl = document.getElementById(nextid).nextSibling;

      allWrapperEl.forEach(hideAllResults)
      if (name !== value) {
        if (name === "Name") setSelectedNameStage(value)

        if (name === "Age") setSelectedAgeStage(value)

        if (name === "Education") setSelectedEduStage(value)

        if (name === "WorkHistory") setSelectedWorkStage(value)

        currentWrapperEl.classList.remove("hide")
        currentStageWrapperEl.classList.remove("hide")
        currentStageWrapperEl.scrollIntoView()
      }
      if (name === value) currentStageWrapperEl.classList.add("hide")
    }
  }

  const createStageResultId = stageValue => {
    // To convert Lower Case
    let lowerCaseText = stageValue.toLowerCase()
    lowerCaseText = lowerCaseText.replace(/\s/g, "-")

    return lowerCaseText
  }

  const getCurrentStageState = type => {
    switch (type) {
      case "Name":
        return selectedNameStage
      case "Age":
        return selectedAgeStage
      case "Education":
        return selectedEduStage
      case "WorkHistory":
        return selectedWorkStage
      default:
        return
    }
  }

  const StageIndicators = ({ indicators, currentindex }) => {
    const indicatorsHtml = []

    for (let i = 0; i < indicators; i++) {
      let activClass = currentindex >= i ? "active" : ""
      indicatorsHtml.push(
        <button
          key={i + 1}
          type={"button"}
          tabindex="-1"
          className={`stage-indicators ${activClass}`}
          aria-label={i + 1}
        ></button>
      )
    }
    return indicatorsHtml
  }

  const SelectOptions = ({ options }) => {
    let currentStageOptions =
      options.length > 0 &&
      options.map((stage_value, i) => {
        return (
          <React.Fragment key={i}>
            {i === 0 && (
              <option
                key={i}
                value={stage_value.value_mapping.document.data.type}
              >
                {stage_value.value_mapping.document.data.type}
              </option>
            )}
            <option
              key={i + 1}
              value={stage_value.value_mapping.document.data.value}
            >
              {stage_value.value_mapping.document.data.value}
            </option>
          </React.Fragment>
        )
      })

    return currentStageOptions
  }

  let stagesList =
    stages.length > 0 &&
    stages.map((stage, i) => {
      /*let currentStageOptions =
        stage.stage.document.data.values.length > 0 &&
        stage.stage.document.data.values.map((stage_value, i) => {

          const selected = (selectedNameStage === stage_value.value_mapping.document.data.type) ? 'selected' : '';
          return (
            <>
              {i === 0 && (
                <option
                  key={i}
                  value={stage_value.value_mapping.document.data.type}
                >
                  {stage_value.value_mapping.document.data.type}
                </option>
              )}
              <option
                key={i + 1}
                value={stage_value.value_mapping.document.data.value}
                {...selected}
              >
                {stage_value.value_mapping.document.data.value}
              </option>
            </>
          )
        })*/

      let currentStageResults =
        stage.stage.document.data.values.length > 0 &&
        stage.stage.document.data.values.map((result, r) => {
          return (
            <div
              key={r}
              className={
                `result-wrap result-wrap-` +
                createStageResultId(stage.stage.document.data.type) +
                ` hide`
              }
              id={
                `result-` +
                createStageResultId(result.value_mapping.document.data.value)
              }
            >
              <div className={`result-summary col col-md-10 offset-md-1`}>
                <PrismicRichText
                  field={result.value_mapping.document.data.summary.richText}
                />
              </div>
              <div className={`result-source col col-md-8 offset-md-2`}>
                <PrismicRichText
                  field={result.value_mapping.document.data.source.richText}
                />
              </div>
            </div>
          )
        })

      const resIdPrefix =
        `cv-criteria-result-` +
        createStageResultId(stage.stage.document.data.type)
      var idPrefix =
        `cv-criteria-` + createStageResultId(stage.stage.document.data.type)

      const currentStageState = {
        stage: stage.stage.document.data,
        options: stage.stage.document.data.values,
      }


      if(i === (stages.length - 1)) allDisableStages.push(currentStageState)

      disableStages =
        allDisableStages.length > 0 &&
        allDisableStages.map((disableStage, s) => {
          let disableSelectValue = getCurrentStageState(disableStage.stage.type)

          return (
            <div key={s} className={`disabled-` + disableStage.stage.type}>
              <label className={(s===0) ? 'name-label' : ''} htmlFor={"disable-" + disableStage.stage.type}>
                {disableStage.stage.label}
              </label>
              <select
                value={disableSelectValue}
                className="form-select disabled"
                aria-label={`Disabled ` + disableStage.stage.type}
                name={"disable-" + disableStage.stage.type}
                disabled
              >
                {/*disableStage.options*/}
                <SelectOptions options={disableStage.options} />
              </select>
            </div>
          )
        })
      //pushing data afterwards
      if(i !== (stages.length - 1)) allDisableStages.push(currentStageState)

      let selectValue = getCurrentStageState(stage.stage.document.data.type)

      return (
        <div key={i} className={`cv-criteria`} id={idPrefix}>
          <div
            className={`cv-criteria-select row g-0 ${
              currentActiveStage < i ? "hide" : ""
            }`}
          >
            <div className={`col col-md-4 offset-md-4`}>
              {disableStages}
              <div className="flex-vc">
                <label className={(i===0) ? 'name-label' : ''} htmlFor={stage.stage.document.data.type}>
                  {stage.stage.document.data.label}
                </label>
              <Tooltip tooltipContent={stage.stage.document.data.help_text}/>
              </div>
              <select
                value={selectValue}
                onChange={handleChange}
                className="form-select"
                aria-label={stage.stage.document.data.type}
                name={stage.stage.document.data.type}
                id={stage.stage.document.data.type}
              >
                {/*currentStageOptions*/}
                <SelectOptions options={stage.stage.document.data.values} />
              </select>

              <div className={`black-stripes`}>
                {i === 0 && <p className={`h3 black-stripes-title`}>Summary</p>}
                <div className={`black-stripe`} />
                <div className={`black-stripe`} />
                <div className={`black-stripe d-md-block`} />
                <div className={`black-stripe d-md-block`} />
              </div>
            </div>
            <div className={`wrap-indicators col col-md-1 offset-md-10`}>
              <StageIndicators indicators={stages.length} currentindex={i} />
            </div>
          </div>
          <div
            style={{
              backgroundColor: stage.stage.document.data.background_colour,
            }}
            className={`cv-criteria-result row g-0 hide`}
            id={resIdPrefix}
          >
            {currentStageResults}
            <div className={`next-stage`}>
              <button
                onClick={e => handleActivateNextStage(i + 1, idPrefix, e)}
              >
                <img src={NextImage} alt={"Go to next stage"} />
              </button>
            </div>
          </div>
        </div>
      )
    })

  return (
    <section className={`container-fuild cv-criterias`}>
      <div className={`cv-criterias-title col col-md-6 offset-md-3`}>
        {title}
      </div>
      {stagesList}
      <div className={`cv-criteria`} id={`cv-criteria-summary`}>
        <div
          className={`cv-criteria-select summary row g-0 flex-column-reverse flex-md-row ${
            currentActiveStage < stages.length ? "hide" : ""
          }`}
        >
          <div className={`col-sm-12 col-md-4 offset-md-1 mt-0 mt-md-0`}>
            {disableStages}
            <CTALink
              to={summary.about_cta.document.data.href}
              className="btn btn-primary mb-3 me-md-4 d-md-none"
            >
              {summary.about_cta.document.data.label}
            </CTALink>

            <CTALink
              to={summary.download_cta.document.data.href}
              target={`_blank`}
              className="btn btn-primary mb-3 white d-md-none"
              rel={`noreferrer`}
            >
              {summary.download_cta.document.data.label}
            </CTALink>
          </div>
          <div className={`col-sm-12 col-md-5 offset-md-1`}>
            <h2>{summary.title.replace("very likely",
              score >= 80 ? "much more likely" :
              score >= 50 ? "more likely" :
              score >= 20 ? "less likely" :
              "much less likely"
            )}</h2>
            <PrismicRichText field={summary.description.richText} />
            <CTALink
              to={summary.about_cta.document.data.href}
              className="btn btn-primary mb-3 me-md-4 d-none d-md-inline-block"
            >
              {summary.about_cta.document.data.label}
            </CTALink>

            <CTALink
              to={summary.download_cta.document.data.href}
              target={`_blank`}
              className="btn btn-primary mb-3 white d-none d-md-inline-block"
              rel={`noreferrer`}
            >
              {summary.download_cta.document.data.label}
            </CTALink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cvswapout
