import React from "react"
import { graphql } from "gatsby"
//import PropTypes from "prop-types"
import HeroBannerSingle from "./HeroBannerSingle.js"
import HeroBannerCarousel from "./HeroBannerCarousel.js"

import "./HeroBanner.scss"

export const heroBannerFragment = graphql`
  fragment heroBannerData on PrismicCvswapoutHeroImages {
    data {
      name
      type
      body {
        ... on PrismicCvswapoutHeroImagesDataBodyImageSlide {
          id
          slice_type
          items {
            title
            description
            image {
              localFile {
    						...heroBannerImage
    					}
              alt
            }
          }
        }
      }
    }
  }
`

const HeroBanner = ({ data }) => {
  if (!data.body[0]) return null
  if (!data.body[0].items[0]) return null

  const isCarousal = data.type === "Carousal" ? true : false
  const slides = data.body[0].items // may be set this as non-repetable in prisimc

  return (
    <section className={`hero-banner`}>
      {isCarousal ? (
        <HeroBannerCarousel slides={slides} />
      ) : (
        <HeroBannerSingle data={slides} />
      )}
    </section>
  )
}

export default HeroBanner

/*HeroBanner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    sub_title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.object,
    theme: PropTypes.string,
  }),
}*/
